import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { MEMBER_ACCESS_TOKEN_KEY, MEMBER_REFRESH_TOKEN_KEY } from '@common';
import { ToastService } from '@common/angular';
import { catchError, from, switchMap, throwError } from 'rxjs';

export const httpInterceptor: HttpInterceptorFn = (req, next) => {
  const toast = inject(ToastService);

  return from(Promise.all([Preferences.get({ key: MEMBER_ACCESS_TOKEN_KEY }), Preferences.get({ key: MEMBER_REFRESH_TOKEN_KEY })])).pipe(
    switchMap(([accessToken, refreshToken]) => {
      if (accessToken.value && refreshToken.value) {
        req = req.clone({
          setHeaders: {
            Authorization: `Bearer ${accessToken.value}`,
            'X-Refresh-Token': refreshToken.value,
          },
        });
      }

      return next(req).pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.status === 401) {
            Preferences.remove({ key: MEMBER_ACCESS_TOKEN_KEY });
            Preferences.remove({ key: MEMBER_REFRESH_TOKEN_KEY });
          }
          if (!err.error.message) {
            const errorMessage = JSON.parse(err.error);
            toast.error(errorMessage.message);
            return throwError(() => errorMessage.message);
          }

          toast.error(err.error.message);

          return throwError(() => err.error.message);
        })
      );
    })
  );
};
