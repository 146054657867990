import { Component, inject } from '@angular/core';
import { IonApp, IonRouterOutlet } from '@ionic/angular/standalone';
import { AuthStore } from '../../stores/auth.store';
import { RouterLink } from '@angular/router';
import { SettingStore } from '../../stores/setting.store';

@Component({
  selector: 'app-default',
  imports: [IonRouterOutlet, IonApp, RouterLink],
  templateUrl: './default.layout.html',
  styleUrl: './default.layout.scss',
})
export class DefaultLayout {
  authStore = inject(AuthStore);
  settingStore = inject(SettingStore);
}
