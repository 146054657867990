import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { IonIcon, IonTabBar, IonTabButton, IonTabs, ModalController } from '@ionic/angular/standalone';

@Component({
    selector: 'app-tabs',
    imports: [IonIcon, IonTabButton, IonTabBar, IonTabs, CommonModule],
    templateUrl: './tabs.layout.html',
    styleUrl: './tabs.layout.scss'
})
export class TabsLayout {
  modal = inject(ModalController);
}
