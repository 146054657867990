<div class="absolute inset-0 flex justify-center overflow-hidden md:gap-32 lg:gap-48 bg-primary">
  <section class="relative items-center justify-end hidden basis-1/2 md:flex">
    <img fetchpriority="high" src="./logo_gradient.svg" class="absolute z-0 w-full -translate-y-1/2 top-1/2" />

    <div class="z-10 flex flex-col items-center justify-between h-full py-16">
      <div class="flex flex-col justify-center flex-1 gap-4">
        <img src="./logo_full.svg" />
        <p class="text-xl text-white">웨이팅 시간동안 관광지, 상품을 둘러볼 수 있는 오늘의 삼천포</p>
        <div class="flex gap-4">
          <a href="" class="cursor-pointer"><img src="./google_play_cta.svg" /></a>
          <a href="" class="cursor-pointer"><img src="./apple_app_store_cta.svg" /></a>
        </div>
      </div>

      <div class="flex flex-col gap-4 text-neutral-400">
        <div class="flex justify-start w-full gap-4 text-neutral-300">
          <a class="cursor-pointer hover:underline" routerLink="term" [queryParams]="{ type: 'eula' }">서비스이용약관</a>
          <div class="w-px h-full bg-neutral-700"></div>
          <a class="cursor-pointer hover:underline" routerLink="term" [queryParams]="{ type: 'privacy' }">개인정보처리방침</a>
        </div>
        @if(settingStore.settings()){
        <div class="flex flex-col gap-2">
          <div class="flex gap-2">
            <p>{{ settingStore.get('footer:name') }}</p>
            <p>대표자명 : {{ settingStore.get('footer:ceo') }}</p>
          </div>
          <p>주소 : {{ settingStore.get('footer:address') }}</p>
          <p>사업자등록번호 : {{ settingStore.get('footer:businessNumber') }}</p>
        </div>
        }
        <p>COPYRIGHT © 2024 (주)사천그리다 All Rights Reserved.</p>
      </div>
    </div>
  </section>

  <section class="flex-1 md:max-w-96">
    <div class="relative w-full h-full bg-white select-none md:max-w-md">
      <ion-app>
        <ion-router-outlet />
      </ion-app>
    </div>
  </section>
</div>
