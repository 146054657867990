import { ApplicationConfig, importProvidersFrom, LOCALE_ID, provideZoneChangeDetection } from '@angular/core';
import { provideRouter, withViewTransitions } from '@angular/router';
import { appRoutes } from './app.routes';
import { provideIonicAngular } from '@ionic/angular/standalone';
import { ApiModule } from '@api-client';
import { environment } from '../environments/environment';
import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { httpInterceptor } from './libs/http-interceptor';
import { provideToastr } from 'ngx-toastr';
import { provideMarkdown } from 'ngx-markdown';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(appRoutes, withViewTransitions()),
    provideIonicAngular({
      toastDuration: 3000,
      innerHTMLTemplatesEnabled: true,
    }),
    provideAnimations(),
    provideToastr(),
    provideMarkdown(),
    provideHttpClient(withFetch(), withInterceptors([httpInterceptor])),
    importProvidersFrom([ApiModule.forRoot({ rootUrl: environment.baseUrl })]),
    {
      provide: LOCALE_ID,
      useValue: 'ko',
    },
    provideAnimationsAsync('noop'),
  ],
};
